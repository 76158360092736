import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import {  Spinner, Alert } from 'react-bootstrap'; // เพิ่ม Spinner และ Alert
import { listAllDataUser_API } from '../../../functions/user';
// ตัวอย่างการแปลงฟอนต์เป็น Base64 (นี่เป็นเพียงตัวอย่างเล็ก ๆ ของฟอนต์จริง)
// คุณต้องใช้ฟอนต์เต็มรูปแบบและแปลงเป็น Base64 เอง
const thaiFont = `AAEAAAASAQAABAAgR0RFRrRCsIIAAjIAAAAYGNtYXAB4gk9AAABHAAAAFRnYXNwAAAAEAAAAXGxvY2EAIgABAAABiAAAAGRnbHlmYgEuAAABHAAAAFRnbHlmZgAAJQAAAGwAAABGnhlYWQABAAAABAAAAAJoZ2x5ZgAAAAAAAAYAAADAAAABGhoc3IAAgAAAAMAAAAIoaGVhZAB4AAABQAAAAFGhoZWEAPgAAAAMAAAAGGxvY2EAABQAAACQAAAACBuYW1lAAEIAAAASAAAAA2cAACA3AAABwAAAADxhbmNobwAAGQAAASgAAAAgY3BvcwAACQAAAAB4AAAACAAAAAEAAAAAIAAAADwAAABkAAgAJAAEAJAAIADIAAwADAAIADgAWABwAGQAbABoAGgAcABwAJAARABYAFgAXABcAJAAPABkAGAAZABoAHgAdAB8AJAAqABUAFwAeABYAGQAZABoAHAAsACgAKQArACsALgAwADEAMQAyADMAOAA4ADgAPwBAAEYARgBQAE8ATABFAEcATABIAE0ATABOAFUAVgBZAFsAXgBeAF4AWgBfAF8AWQBaAGIAZQBnAGUAbgBxAHUAeAB6AH0AfQB+AIEAhwCUAI4AjwCOAJAAkACXAJUAmgCjAJ0AqwC+ALUAuQC9ALEAtwC5AL4AwADGAMgAwwDFANUA1gDZANIA2gDZANwA2wDaANoA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANoA2gDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaANwA2wDZANIA2gDaA`;

function PrintReport() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);

    useEffect(() => {
       listAllDataUser_API()
            .then(response => {
                console.log('API Response:', response.data);
                if (Array.isArray(response.data)) {
                    setUsers(response.data);
                } else if (Array.isArray(response.data.data)) {
                    setUsers(response.data.data);
                } else {
                    console.error('API response is not an array:', response.data);
                    setError(new Error('ข้อมูลไม่ถูกต้อง'));
                }
                setLoading(false);
            })
            .catch(err => {
                console.error('API Error:', err);
                setError(err);
                setLoading(false);
            });
    }, []);

    // สรุปข้อมูล (ตัวอย่าง: จำนวนผู้ใช้แต่ละประเภท)
    const summarizeData = () => {
        const summary = {};
        users.forEach(user => {
            const type = user.type || 'ไม่ระบุ';
            if (summary[type]) {
                summary[type] += 1;
            } else {
                summary[type] = 1;
            }
        });
        return summary;
    };

    const generatePDF = () => {
        // สร้าง instance ของ jsPDF
        const doc = new jsPDF();

        // เพิ่มฟอนต์ภาษาไทย
        doc.addFileToVFS("NotoSansThai-Regular.ttf", thaiFont); // แทนที่ด้วยฟอนต์ที่แปลงแล้ว
        doc.addFont("NotoSansThai-Regular.ttf", "NotoSansThai", "normal");
        doc.setFont("NotoSansThai"); // ตั้งฟอนต์

        // เพิ่มหัวข้อใหญ่
        doc.setFontSize(24);
        doc.text('รายงานผู้ใช้', 105, 15, null, null, 'center');

        // เพิ่มวันที่พิมพ์รายงาน
        doc.setFontSize(12);
        doc.text(`วันที่พิมพ์: ${new Date().toLocaleDateString()}`, 10, 25);

        // สรุปข้อมูล
        const summary = summarizeData();
        const summaryData = [['ประเภท', 'จำนวน']];

        for (const [type, count] of Object.entries(summary)) {
            summaryData.push([type, count.toString()]);
        }

        doc.autoTable({
            head: [summaryData[0]],
            body: summaryData.slice(1),
            startY: 30,
            theme: 'grid',
            headStyles: { fillColor: [100, 100, 255] },
            bodyStyles: { fillColor: [240, 240, 240] },
            alternateRowStyles: { fillColor: [255, 255, 255] },
        });

        // เพิ่มพื้นที่ว่างก่อนตารางที่สอง
        let finalY = doc.lastAutoTable.finalY + 10;

        // สร้างตารางข้อมูลผู้ใช้
        const userTableData = [
            ['ชื่อ', 'เบอร์โทร', 'ประเภท', 'วันที่เลือก'],
            ...users.map(user => [
                user.userName,
                user.phoneNumber || 'ไม่มีข้อมูล',
                user.type || 'ไม่ระบุ',
                user.selectedDate || 'ไม่มีข้อมูล',
            ]),
        ];

        doc.autoTable({
            head: [userTableData[0]],
            body: userTableData.slice(1),
            startY: finalY,
            theme: 'grid',
            headStyles: { fillColor: [100, 100, 255] },
            bodyStyles: { fillColor: [240, 240, 240] },
            alternateRowStyles: { fillColor: [255, 255, 255] },
            styles: { font: "NotoSansThai" }, // ตั้งฟอนต์ให้ตรงกับที่เพิ่มเข้าไป
        });

        // ดาวน์โหลด PDF
        doc.save('report.pdf');
    };

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h2 style={{ marginBottom: '20px' }}>พิมพ์รายงาน PDF</h2>
            {loading ? (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : error ? (
                <Alert variant="danger">
                    เกิดข้อผิดพลาดในการโหลดข้อมูล: {error.message}
                </Alert>
            ) : (
                <>
                    <p>คุณสามารถพิมพ์รายงานการลงทะเบียนผู้ใช้งานได้โดยคลิกที่ปุ่มด้านล่าง:</p>
                    <button
                        onClick={generatePDF}
                        style={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        พิมพ์รายงานเป็น PDF
                    </button>
                </>
            )}
        </div>
    );
}

export default PrintReport;
