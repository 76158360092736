// ../../../components/ListAllDataUser.js

import React, { useState, useEffect } from 'react';
import { listAllDataUser_API } from '../../../functions/user';
import { Table, Button, Modal, Spin, Alert, Input, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment'; // เพิ่มการนำเข้า moment


const ListAllDataUser = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    
    // State สำหรับการค้นหา
    const [searchName, setSearchName] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchPhone, setSearchPhone] = useState('');

    useEffect(() => {
        listAllDataUser_API()
            .then(response => {
                if (Array.isArray(response.data)) {
                    setUsers(response.data);

                } else {
                    console.error('API response is not an array:', response);
                    setError(new Error('ข้อมูลไม่ถูกต้อง'));
                }
                setLoading(false);
                console.log(selectedUser)
            })
            .catch(err => {
                console.error('API Error:', err);
                setError(err);
                setLoading(false);
            });
    }, [selectedUser]);

    const handleShow = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleClose = () => {
        setSelectedUser(null);
        setShowModal(false);
    };

    // ฟังก์ชันสำหรับกรองข้อมูลตามการค้นหา
    const getFilteredUsers = () => {
        return users.filter(user => {
            const matchesName = user.userName.toLowerCase().includes(searchName.toLowerCase());
            const matchesDate = searchDate ? moment(user.selectedDate).format('YYYY-MM-DD') === searchDate : true;
            const matchesPhone = user.phoneNumber.includes(searchPhone);
            return matchesName && matchesDate && matchesPhone;
        });
    };

    const filteredUsers = getFilteredUsers();

    const columns = [
        {
            title: 'ชื่อ',
            dataIndex: 'userName',
            key: 'userName',
            sorter: (a, b) => a.userName.localeCompare(b.userName),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="ค้นหาด้วยชื่อ"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        ค้นหา
                    </Button>
                    <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                        ล้าง
                    </Button>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        },
        {
            title: 'เบอร์โทร',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
        },
        {
            title: 'ประเภท',
            dataIndex: 'type',
            key: 'type',
            filters: [
                { text: 'บ้านเรือน', value: 'บ้านเรือน' },
                { text: 'สถานประกอบการ', value: 'สถานประกอบการ' },
                { text: 'อนุเคราะห์', value: 'อนุเคราะห์' },
            ],
            onFilter: (value, record) => record.type === value,
        },
        {
            title: 'รายละเอียด',
            key: 'details',
            render: (text, record) => (
                <Button type="primary" onClick={() => handleShow(record)}>
                    ดูรายละเอียด
                </Button>
            ),
        },
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return (
            <Alert
                message="เกิดข้อผิดพลาด"
                description={`เกิดข้อผิดพลาดในการโหลดข้อมูล: ${error.message}`}
                type="error"
                showIcon
                style={{ margin: '20px' }}
            />
        );
    }

    return (
        <div className="container mt-4">
            <h2 className="text-center mb-4">รายการผู้ใช้ทั้งหมด</h2>

            {/* ส่วนค้นหา */}
            <Row gutter={[16, 16]} className="mb-4">
                <Col xs={24} sm={8}>
                    <Input
                        placeholder="ค้นหาด้วยชื่อ"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        allowClear
                    />
                </Col>
                <Col xs={24} sm={8}>
                    <Input
                        type="date"
                        placeholder="ค้นหาด้วยวันที่"
                        value={searchDate}
                        onChange={(e) => setSearchDate(e.target.value)}
                        allowClear
                    />
                </Col>
                <Col xs={24} sm={8}>
                    <Input
                        placeholder="ค้นหาด้วยเบอร์โทร"
                        value={searchPhone}
                        onChange={(e) => setSearchPhone(e.target.value)}
                        allowClear
                    />
                </Col>
            </Row>

            <Table
                columns={columns}
                dataSource={filteredUsers}
                rowKey="_id"
                pagination={{ pageSize: 10 }}
                locale={{
                    emptyText: 'ไม่มีข้อมูลผู้ใช้ที่ตรงกับการค้นหา',
                }}
            />

            {/* Modal */}
            <Modal
                open={showModal}
                title="รายละเอียดผู้ใช้"
                onCancel={handleClose}
                footer={[
                    <Button key="close" onClick={handleClose}>
                        ปิด
                    </Button>,
                ]}
            >
                {selectedUser && (
                    <div>
                        <p><strong>ชื่อ:</strong> {selectedUser.userName}</p>
                        <p><strong>เบอร์โทร:</strong> {selectedUser.phoneNumber}</p>
                        <p><strong>ประเภท:</strong> {selectedUser.type}</p>
                        <p><strong>บ้านเลขที่:</strong> {selectedUser.houseNumber}</p>
                        <p><strong>ซอย:</strong> {selectedUser.soi}</p>
                        <p><strong>ถนน:</strong> {selectedUser.road}</p>
                        <p><strong>ตำบล:</strong> {selectedUser.subdistrict}</p>
                        <p><strong>อำเภอ:</strong> {selectedUser.district}</p>
                        <p><strong>วันที่จอง:</strong> {selectedUser.selectedDate ? moment(selectedUser.selectedDate).format('DD/MM/YYYY') : 'ยังไม่ได้เลือก'}</p>
                        <p><strong>เวลาจอง:</strong> {selectedUser.createdAt ? moment(selectedUser.createdAt).format(' HH:mm:ss') : 'ยังไม่ได้บันทึก'}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default ListAllDataUser;
