import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch } from 'react-redux';
import AdminRoute from './components/routes/AdminRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/layouts/navbar';
import userMain from './components/pages/2user/userMain';
import Login from './components/pages/auth/login';
import RegistrationForm from './components/pages/auth/register';
import { currentUser } from './components/functions/auth';
import EditBookingForm from './components/pages/1admin/EditBookingForm';
import BookingForm from './components/pages/2user/create';
import Calendar_function from './components/pages/2user/calendar';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const idTokenResult = localStorage.token;
    if (idTokenResult) {
      currentUser(idTokenResult)
        .then(res => {
          dispatch({
            type: 'LOGGED_IN_USER',
            payload: {
              name: res.data.name,
              token: idTokenResult,
              role: res.data.role,
              id: res.data._id
            }
          });
        })
        .catch(err => {
          dispatch({
            type: 'LOGOUT',
            payload: null
          });
          console.log(err);
        });
    }
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        {/* ToastContainer วางที่นี่เพื่อให้สามารถใช้ได้ทุกที่ในแอป */}
        <ToastContainer />
        <Navbar />
        <Switch>
          <Route exact path="/home" component={userMain} />
          <Route exact path="/Calendar" component={Calendar_function} />
          <AdminRoute exact path="/EditBookingForm" component={EditBookingForm} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={RegistrationForm} />
          <Route exact path="/createForm" component={BookingForm} />
          {/* เพิ่ม Route อื่นๆ ตามต้องการ */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
