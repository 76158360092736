import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Select, Space, message, Spin } from 'antd'; // เพิ่ม Spin
import { getEditUser_API, updateUser_API } from '../functions/user'; // Ensure these functions are correctly implemented

const { Option } = Select;

const EditUserDrawer = ({ dataForEdit }) => { // Renamed for clarity
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); 
  const [updating, setUpdating] = useState(false);

  const idTokenResult = localStorage.getItem('token'); // Improved retrieval

  useEffect(() => {
    if (open && dataForEdit && idTokenResult) {
      setLoading(true);
      getEditUser_API(idTokenResult, dataForEdit).then(res => {
          form.setFieldsValue({
            userName: res.data.userName,
            type: res.data.type,
            houseNumber: res.data.houseNumber,
            soi: res.data.soi,
            road: res.data.road,
            phoneNumber: res.data.phoneNumber,
            description: res.data.description,
          });
        })
        .catch(err => {
          console.error(err);
          message.error('ไม่สามารถดึงข้อมูลผู้ใช้ได้');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, dataForEdit, form, idTokenResult]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields(); // Reset form when closing
  };

  const onFinish = (values) => {
    setUpdating(true);
    updateUser_API(idTokenResult, dataForEdit, values)
      .then(() => {
        message.success('อัพเดตข้อมูลผู้ใช้สำเร็จ!');
        form.resetFields();
        setOpen(false);
      })
      .catch(err => {
        console.error(err);
        message.error('ไม่สามารถอัพเดตข้อมูลผู้ใช้ได้');
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('กรุณาตรวจสอบข้อมูลในฟอร์ม');
  };

  return (
    <>
      <Button type="link" icon={<EditOutlined />} onClick={showDrawer} />
      <Drawer
        title="แก้ไขผู้ใช้"
        width={720}
        onClose={onClose}
        open={open}
        style={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>ยกเลิก</Button>
            <Button onClick={() => form.submit()} type="primary" loading={updating}>
              ส่ง
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            requiredMark
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="userName"
                  label="ชื่อ"
                  rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="type"
                  label="ประเภท"
                >
                  <Select placeholder="กรุณาเลือกประเภท">
                    <Option value="บ้านเรือน">บ้านเรือน</Option>
                    <Option value="สถานประกอบการ">สถานประกอบการ</Option>
                    <Option value="อนุเคราะห์">อนุเคราะห์</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="houseNumber"
                  label="บ้านเลขที่"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="soi"
                  label="ซอย"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="road"
                  label="ถนน"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="เบอร์โทรศัพท์"
                  rules={[
                    { pattern: /^\d{10}$/, message: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="รายละเอียด"
                >
                  <Input.TextArea rows={4} placeholder="กรอกรายละเอียดเพิ่มเติม" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditUserDrawer;
