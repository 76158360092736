import React, { useEffect, useState } from 'react';
import { Calendar, Badge, Spin, Alert } from 'antd';
import { filterDateInformation_API } from '../../functions/user';

const CalendarFunction = () => {
  const [bookingData, setBookingData] = useState([]); // เริ่มต้นเป็นอาร์เรย์ว่าง
  const [loading, setLoading] = useState(true); // สถานะการโหลดข้อมูล
  const [error, setError] = useState(null); // สถานะข้อผิดพลาด

  useEffect(() => {
    filterDateInformation_API()
      .then((res) => {
        setBookingData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching booking data:', err);
        setError(err);
        setLoading(false);
      });
  }, []);

  const getListData = (value) => {
    const formattedDate = value.format('YYYY-MM-DD');
    // ฟิลเตอร์ข้อมูลที่ตรงกับวันที่
    const dataForDate = bookingData.filter((item) => item.date === formattedDate);
    return dataForDate || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events" style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {listData.map((item, index) => (
          <li key={index} style={{ marginBottom: '4px' }}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
        <Alert
          message="Error"
          description="เกิดข้อผิดพลาดในการโหลดข้อมูลปฏิทิน กรุณาลองใหม่อีกครั้ง"
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <div
        style={{
          width: '90%',
          maxWidth: '1000px',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h3>ปฏิทินการจอง</h3>
        <Calendar cellRender={dateCellRender} />
      </div>
    </div>
  );
};

export default CalendarFunction;
