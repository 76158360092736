// EditBookingForm.js
import React, { useEffect, useState } from 'react';
import { Calendar, Modal, Badge, Layout, Menu, List, Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteUser_API, filterDateInformation_API } from '../../functions/user';
import 'jspdf-autotable';  // ใช้สำหรับการสร้างตารางใน PDF
import CreateItems from '../../layouts/createItems'; // ตรวจสอบ path ให้ถูกต้อง
import PrintReport from './servicePages/printReport';
import ListAllDataUser from './servicePages/listAllDataUser';

const { Sider, Content } = Layout;

const EditBookingForm = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [bookingData, setBookingData] = useState([]);
    const [menuKey, setMenuKey] = useState('3'); // กำหนดค่าเริ่มต้นเป็นเมนูที่ 3 (ปฏิทินการจอง)
    const idTokenResult = localStorage.token;


    useEffect(() => {
        const fetchBookingData = async () => {
            try {
                const res = await filterDateInformation_API();
                setBookingData(res.data || []);
            } catch (err) {
                console.error(err);
                message.error('ไม่สามารถดึงข้อมูลการจองได้');
            }
        };
        fetchBookingData();
    }, [isModalVisible,selectedDate]); // ลบ selectedDate และ isModalVisible ออกจาก dependencies เพื่อป้องกันการเรียก API ซ้ำซ้อน

    const getListData = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        return bookingData?.filter((item) => item.date === formattedDate) || [];
    };

    const onSelectDate = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        const filter = bookingData.filter((d) => d.date === formattedDate);
        setFilterData(filter);
        setSelectedDate(formattedDate);
        setIsModalVisible(true); // เปิด Modal เมื่อเลือกวันที่
    };

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item, index) => (
                    <Badge key={`${item.type}-${index}`} status={item.type} text={item.content} />
                ))}
            </ul>
        );
    };



    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleMenuClick = ({ key }) => {
        setMenuKey(key); // เปลี่ยนสถานะเมนูที่ถูกเลือก
    };

    // ฟังก์ชันสำหรับการลบผู้ใช้
    const handleDelete = async (nameUser, date) => {
        try {
            await deleteUser_API(idTokenResult, nameUser, date)
            message.success(`ลบผู้ใช้: ${nameUser} ในวันที่: ${date}`);

            // หลังจากลบแล้ว ให้ปรับปรุงสถานะ bookingData และ filterData
            setBookingData(prevData => prevData.filter(item => !(item.date === date && item.user.includes(nameUser))));
            setFilterData(prevFilter => prevFilter.map(item => {
                if (item.date === date) {
                    return { ...item, user: item.user.filter(u => u !== nameUser) };
                }
                return item;
            }));
        } catch (err) {
            console.error(err);
            message.error('เกิดข้อผิดพลาดในการลบผู้ใช้');
        }
    };

    // เนื้อหาของแต่ละเมนู
    const renderContent = () => {
        switch (menuKey) {
            case '1':
                return (
                    <div>
                        <h2>ผู้ที่ลงทะเบียนทั้งหมด</h2>
                        {/* แสดงผู้ลงทะเบียนทั้งหมด */}
                        <p>แสดงรายชื่อผู้ลงทะเบียนทั้งหมดที่นี่</p>
                    <ListAllDataUser/>
                    </div>
                );
            case '2':
                return (
                    <div>
                        <h2>ผู้ลงทะเบียนใหม่</h2>
                        {/* ฟอร์มสำหรับผู้ลงทะเบียนใหม่ */}
                        <p>แสดงฟอร์มการลงทะเบียนใหม่ที่นี่</p>
                    </div>
                );
            case '3':
                return (
                    <div>
                        <h2>ปฏิทินการจอง</h2>
                        <div style={{ maxWidth: '100%', overflowX: 'auto', marginBottom: 20 }}>
                            <Calendar
                                cellRender={dateCellRender}
                                onSelect={onSelectDate}
                                style={{ width: '100%', maxWidth: '100%' }}
                            />
                        </div>
                    </div>
                );

            case '4':
                return (
                    <div>
                        <h2>คำขอยกเลิก</h2>
                        {/* จัดการคำขอยกเลิก */}
                        <p>แสดงคำขอยกเลิกที่นี่</p>
                    </div>
                );

                case '5':
                  return (
                      <div>
                          {/* จัดการคำขอยกเลิก */}
                          <PrintReport/>
                      </div>
                  );

            case '6':
                return (
                    <div>
                        <h2>ติดต่อผู้ดูแลระบบ</h2>
                        {/* แสดงข้อมูลการติดต่อผู้ดูแลระบบ */}
                        <p>แสดงข้อมูลการติดต่อผู้ดูแลระบบที่นี่</p>
                    </div>
                );
            default:
                return <p>กรุณาเลือกเมนู</p>;
        }
    };

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                {/* Sidebar สำหรับเมนู */}
                <Sider width={200} className="site-layout-background">
                    <Menu
                        mode="inline"
                        selectedKeys={[menuKey]}
                        style={{ height: '100%', borderRight: 0 }}
                        onClick={handleMenuClick}
                    >
                        <Menu.Item key="1">ผู้ลงทะเบียนทั้งหมด</Menu.Item>
                        <Menu.Item key="2">ผู้ลงทะเบียนใหม่</Menu.Item>
                        <Menu.Item key="3">ปฏิทินการจอง</Menu.Item>
                        <Menu.Item key="4">คำขอยกเลิก</Menu.Item>
                        <Menu.Item key="5">พิมพ์รายงาน</Menu.Item>
                        <Menu.Item key="6">ติดต่อผู้ดูแลระบบ</Menu.Item>
                    </Menu>
                </Sider>

                {/* Content หลัก */}
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
                        {renderContent()}
                    </Content>
                </Layout>
            </Layout>

            <Modal
                title="ยืนยันการจอง"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null} // ซ่อนปุ่มตกลงและยกเลิกใน footer
            >
                <p>วันที่: {selectedDate}</p>
                <h6>รายชื่อผู้ที่ลงทะเบียน</h6>
                {filterData.length > 0 && filterData[0].user?.length > 0 ? (
                    <List
                        itemLayout="horizontal"
                        dataSource={filterData[0].user}
                        renderItem={(user) => (
                            <List.Item
                                actions={[
                                    <CreateItems key={`edit-${user}`} dataForEdit={{ user, date: selectedDate }} />,
                                    <Button
                                        key={`delete-${user}`}
                                        type="link"
                                        danger
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleDelete(user, selectedDate)}
                                    >
                                        ลบ
                                    </Button>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={user}
                                />
                            </List.Item>
                        )}
                    />
                ) : (
                    <p>ไม่มีผู้ลงทะเบียน</p>
                )}
            </Modal>
        </>
    );
};

export default EditBookingForm;
