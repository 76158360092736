// src/functions/user.js
import axios from 'axios';

// ฟังก์ชันสำหรับสร้างผู้ใช้
export const formUser_API = async (group) => {
    const url = `${process.env.REACT_APP_API}/bookingData`;
    console.log(url);
    const headers = { authToken: 'null' }; // ตรวจสอบว่า 'null' เป็นสตริงที่ถูกต้องหรือไม่
    const data = { formUser: group };
    console.log(data)
    try {
        const response = await axios.post(url, data, { headers });
        return response.data;
    } catch (error) {
        console.error("Error creating person:", error);
        throw new Error("[API Error] ไม่สามารถส่งข้อมูลไปบันทึกได้");
    }
};

// ฟังก์ชันสำหรับดึงข้อมูลการจองตามวันที่
export const filterDateInformation_API = async () => {
    const url = `${process.env.REACT_APP_API}/filterDateInformation_API`;
    const headers = { authToken: 'null' }; // ตรวจสอบความถูกต้องของ headers

    try {
        const response = await axios.get(url, { headers });
        return response;
    } catch (error) {
        console.error("Error fetching booking information:", error);
        throw new Error("[API Error] ไม่สามารถดึงข้อมูลการจองได้");
    }
};

// ฟังก์ชันสำหรับดึงข้อมูลผู้ใช้ ทุกคนเพื่อเเสดงหน้าผู้ใช้ทั้งหมด
export const listAllDataUser_API = async () => {
  
    const url = `${process.env.REACT_APP_API}/listAllDataUser`;
    const headers = { authToken: null };
    try {
        const response = await axios.get(url, { headers }); // ส่ง data และ headers อย่างถูกต้อง
        return response;
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw new Error("[API Error] ไม่สามารถดึงข้อมูลผู้ใช้ได้");
    }
};

// ฟังก์ชันสำหรับลบผู้ใช้
export const deleteUser_API = async (authToken, userName, date) => {
    const url = `${process.env.REACT_APP_API}/deleteUser`;
    const headers = { authToken: authToken }; // ทำให้สม่ำเสมอกับชื่อ headers
    const data = { date:date,userName:userName };
    console.log(data);
    

    try {
        const response = await axios.delete(url, { data,headers });
        return response;
    } catch (error) {
        console.error("Error deleting user:", error);
        throw new Error("[API Error] ไม่สามารถลบผู้ใช้ได้");
    }
};

// ฟังก์ชันสำหรับดึงข้อมูลผู้ใช้เพื่อแก้ไข
export const getEditUser_API = async (authToken, dataForEdit) => {
  console.log();
  
    const url = `${process.env.REACT_APP_API}/selectUser`;
    const headers = { authToken: authToken };
    const data = { date: dataForEdit.date ,userName:dataForEdit.user };

    try {
        const response = await axios.post(url, data, { headers }); // ส่ง data และ headers อย่างถูกต้อง
        return response;
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw new Error("[API Error] ไม่สามารถดึงข้อมูลผู้ใช้ได้");
    }
};

// ฟังก์ชันสำหรับอัพเดตผู้ใช้ (เพิ่มเข้ามาเพื่อใช้ใน CreateItems)
export const updateUser_API = async (authToken, user, values) => {
    const url = `${process.env.REACT_APP_API}/updateUser`;
    const headers = { authToken: authToken };
    const formdata = { data: values, date:user};
     console.log(formdata)



    try {
        const response = await axios.put(url, formdata, { headers });
        return response;
    } catch (error) {
        console.error("Error updating user:", error);
        throw new Error("[API Error] ไม่สามารถอัพเดตผู้ใช้ได้");
    }
};



