import React, { useEffect, useState } from 'react';
import { Steps, Form, Input, Select, Button, Calendar, Badge, Spin, Alert } from 'antd';
import { formUser_API, filterDateInformation_API } from '../../functions/user';
import { toast } from 'react-toastify'; // ไม่ต้องนำเข้า ToastContainer ที่นี่แล้ว
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; // ใช้สำหรับการจัดการวันที่
import { useHistory } from 'react-router-dom'; // ใช้สำหรับการนำทาง

const { Option } = Select;
const { Step } = Steps;

const BookingForm = () => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateValid, setIsDateValid] = useState(false); // ตรวจสอบว่าวันที่ถูกต้องหรือไม่
  const [bookingData, setBookingData] = useState([]); // เริ่มต้นเป็นอาร์เรย์ว่าง
  const [loading, setLoading] = useState(true); // สถานะการโหลดข้อมูล
  const [error, setError] = useState(null); // สถานะข้อผิดพลาด
  const [fromUser, setFormUser] = useState({});

  const history = useHistory(); // ใช้ useHistory สำหรับการนำทาง

  const holidays = ['2024-12-05', '2024-12-10']; // เพิ่มวันที่ขัตฤกษ์ที่ต้องการ

  useEffect(() => {
    filterDateInformation_API()
      .then(res => {
        setBookingData(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching booking data:', err);
        setError(err);
        setLoading(false);
      });
  }, []); // โหลดข้อมูลเพียงครั้งเดียวเมื่อคอมโพเนนต์ถูก mount

  const onValuesChange = (changedValues, allValues) => {
    setFormUser(allValues);
  };

  const getListData = (value) => {
    if (!bookingData) return [];
    const formattedDate = value.format('YYYY-MM-DD');
    // ฟิลเตอร์ข้อมูลที่ตรงกับวันที่
    const dataForDate = bookingData.filter(item => item.date === formattedDate);
    return dataForDate || [];
  };

  const onSelectDate = (value) => {
    const newDate = value.format('YYYY-MM-DD'); // เก็บค่าวันที่ที่เลือก
    const today = moment().format('YYYY-MM-DD'); // วันที่ปัจจุบัน

    // ตรวจสอบวันที่ที่เลือก หากต่ำกว่าวันที่ปัจจุบัน ให้แสดงการแจ้งเตือน
    if (moment(newDate).isBefore(today)) {
      toast.error('เลือกวันที่ตั้งแต่ปัจจุบันเป็นต้นไป', { autoClose: 2000 });
      setIsDateValid(false);
    } else if (value.day() === 0 || value.day() === 6 || holidays.includes(newDate)) {
      // หากเลือกวันเสาร์, อาทิตย์ หรือวันหยุดนักขัตฤกษ์
      toast.error('ไม่สามารถจองในวัน เสาร์ อาทิตย์ และวันหยุดนักขัตฤกษ์', { autoClose: 2000 });
      setIsDateValid(false);
    } else {
      setSelectedDate(newDate);
      setIsDateValid(true);
    }
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events" style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {listData.map((item, index) => (
          <li key={index} style={{ marginBottom: '4px' }}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 8 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
  };

  const next = async () => {
    if (current === 1 && !isDateValid) {
      // ถ้าอยู่ในขั้นตอนเลือกวันที่และวันที่ไม่ถูกต้อง
      toast.error('โปรดตรวจสอบข้อมูลให้ถูกต้อง', { autoClose: 2000 });
      return;
    }

    try {
      // ตรวจสอบฟอร์มในขั้นตอนปัจจุบัน
      if (current === 0) {
        await form.validateFields(); // ตรวจสอบว่าฟอร์มในขั้นตอนนี้ถูกต้องหรือไม่
      }
      setCurrent(current + 1);
    } catch (error) {
      toast.error('โปรดตรวจสอบข้อมูลให้ถูกต้อง', { autoClose: 2000 });
      console.log("Validation failed:", error);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = () => {
    if (!selectedDate) {
      toast.error('โปรดเลือกวันที่จอง', { autoClose: 2000 });
      return;
    }
    const formData = { ...fromUser, selectedDate: selectedDate };
    formUser_API(formData)
      .then(res => {
        toast.success('ทำการจองสำเร็จ!', { autoClose: 2000 });
        history.push('/home'); // นำทางไปยังหน้า home หลังจาก toast ปิด
      })
      .catch(err => {
        console.error('Error submitting booking:', err);
        toast.error('เกิดข้อผิดพลาดในการทำการจอง กรุณาลองใหม่อีกครั้ง', { autoClose: 2000 });
      });
  };

  const steps = [
    {
      title: 'ข้อมูลส่วนตัว',
      content: (
        <>
          <Form.Item
            name="userName"
            label="ชื่อ"
            rules={[{ required: true, message: 'โปรดใส่ชื่อของคุณ!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label="ประเภทสิ่งก่อสร้าง"
            rules={[{ required: true, message: 'โปรดเลือกประเภทสิ่งก่อสร้าง!' }]}
          >
            <Select placeholder="เลือกประเภทสิ่งก่อสร้าง">
              <Option value="บ้านเรือน">บ้านเรือน</Option>
              <Option value="สถานประกอบการ">สถานประกอบการ</Option>
              <Option value="อนุเคราะห์">อนุเคราะห์</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="houseNumber"
            label="บ้านเลขที่"
            rules={[{ required: true, message: 'โปรดใส่บ้านเลขที่!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="soi"
            label="ซอย"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="road"
            label="ถนน"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="subdistrict"
            label="ตำบล"
            rules={[{ required: true, message: 'โปรดใส่ตำบล!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="district"
            label="อำเภอ"
            rules={[{ required: true, message: 'โปรดใส่อำเภอ!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="เบอร์โทรศัพท์"
            rules={[
              { required: true, message: 'โปรดใส่เบอร์โทรศัพท์!' },
              { pattern: /^[0-9]{10}$/, message: 'โปรดใส่เบอร์โทรศัพท์ที่ถูกต้อง!' },
            ]}
          >
            <Input />
          </Form.Item>

        </>
      ),
    },
    {
      title: 'เลือกวันที่จอง',
      content: (
        <>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <Spin size="large" />
            </div>
          ) : error ? (
            <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
              <Alert
                message="เกิดข้อผิดพลาด"
                description="ไม่สามารถโหลดข้อมูลปฏิทินได้ กรุณาลองใหม่อีกครั้ง"
                type="error"
                showIcon
              />
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
              <div
                style={{
                  width: '90%',
                  maxWidth: '1000px',
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '10px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Calendar
                  cellRender={dateCellRender}
                  onSelect={onSelectDate}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'ยืนยันการจอง',
      content: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            width: '100%',
            maxWidth: '600px',
            textAlign: 'center',
            padding: '20px',
            border: '1px solid #e5e5e5',
            borderRadius: '10px',
            backgroundColor: '#fafafa'
          }}>
            <h3>ยืนยันการจอง</h3>
            <p><strong>ชื่อ:</strong> {fromUser.userName}</p>
            <p><strong>เบอร์โทรศัพท์:</strong> {fromUser.phoneNumber}</p>
            <p><strong>ประเภทสิ่งก่อสร้าง:</strong> {fromUser.type}</p>
            <p><strong>บ้านเลขที่:</strong> {fromUser.houseNumber}</p>
            <p><strong>ซอย:</strong> {fromUser.soi}</p>
            <p><strong>ถนน:</strong> {fromUser.road}</p>
            <p><strong>ตำบล:</strong> {fromUser.subdistrict}</p>
            <p><strong>อำเภอ:</strong> {fromUser.district}</p>
            <p><strong>วันที่จอง:</strong> {selectedDate ? moment(selectedDate).format('DD/MM/YYYY') : 'ยังไม่ได้เลือก'}</p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        style={{
          maxWidth: 1000,
          margin: 'auto',
          marginTop: '20px',
          overflow: 'auto',
          padding: '20px',
          backgroundColor: '#fff',
          borderRadius: '10px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
        scrollToFirstError
      >
        <Steps current={current} style={{ marginBottom: '20px' }}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>

        <div style={{ marginTop: 20, padding: '20px', minHeight: '200px' }}>
          {steps[current].content}
        </div>

        <div style={{ marginTop: 20, textAlign: 'center' }}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next} style={{ marginRight: '8px' }}>
              ถัดไป
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
              เสร็จสิ้น
            </Button>
          )}
          {current > 0 && (
            <Button onClick={prev}>
              ก่อนหน้า
            </Button>
          )}
        </div>
      </Form>
      {/* ไม่ต้องวาง ToastContainer ที่นี่แล้ว เพราะได้ย้ายไป App.js */}
    </>
  );
};

export default BookingForm;
