import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function Navbar() {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch({ type: 'LOGOUT', payload: null });
    localStorage.removeItem('token'); // ลบ token จาก localStorage
    history.push('/login'); // เปลี่ยนเส้นทางไปที่หน้า login
  };

  const goToPage = (path) => {
    history.push(path); // ใช้เพื่อเปลี่ยนเส้นทางไปยังหน้าอื่น
  };

  return (
    <div style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <ul className="nav justify-content-center" style={{ padding: '10px' }}>
        <li className="nav-item">
          <span className="nav-link active" role="button" onClick={() => goToPage('/home')} style={{ cursor: 'pointer' }}>
            หน้าหลัก
          </span>
        </li>

        {!user && (
          <li className="nav-item">
            <span className="nav-link" role="button" onClick={() => goToPage('/Calendar')} style={{ cursor: 'pointer' }}>
              ปฏิทินการจอง
            </span>
          </li>
        )}



        <li className="nav-item">
          <span className="nav-link" role="button" onClick={() => goToPage('/createForm')} style={{ cursor: 'pointer' }}>
            แบบฟอร์มขอใช้บริการ
          </span>
        </li>


        {user ? (
          <>
            <li className="nav-item">
              <span className="nav-link" role="button" onClick={() => goToPage('/EditBookingForm')} style={{ cursor: 'pointer' }}>
                บริการ
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link" role="button" onClick={logout} style={{ cursor: 'pointer' }}>
                ออกจากระบบ
              </span>
            </li>
          </>
        ) : (
          <li className="nav-item">
            <span className="nav-link" role="button" onClick={() => goToPage('/login')} style={{ cursor: 'pointer' }}>
              เข้าสู่ระบบ
            </span>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
